var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"tableMod",staticClass:"table-modify-css table-scroll-y header-sticky-table header-no-border-table pl-3 table-on-max-height",class:_vm.tableLoading ? 'table-on-loading' : '',attrs:{"headers":_vm.headers,"items":_vm.listCategories,"sort-by":[],"sort-desc":[false, true],"hide-default-header":false,"height":_vm.tableHeight,"multi-sort":"","hide-default-footer":"","items-per-page":10000,"loading":_vm.tableLoading,"loading-text":"Dữ liệu đang được tải xuống"},scopedSlots:_vm._u([(_vm.tableLoading)?{key:"loading",fn:function(){return [_c('span',{staticClass:"d-flex justify-center"},[_vm._v("Dữ liệu đang được tải xuống")])]},proxy:true}:null,(!_vm.tableLoading)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"d-flex justify-center"},[_vm._v("Không có dữ liệu")])]},proxy:true}:null,{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_icon"},[_c('img',{staticClass:"w-max-content",attrs:{"src":item.avatar}})])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_icon"},[_c('span',{staticClass:"w-max-content"},[_vm._v(" "+_vm._s(item.department ? item.department.name : '-')+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_icon"},[_c('span',{staticClass:"i-cng-tc"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-3"},[_c('v-switch',{on:{"change":function($event){return _vm.onChangeActive(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_icon d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"height":"24px","src":require("@/assets/images/ic_edit@2x.png"),"alt":""},on:{"click":function($event){return _vm.editItem(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Sửa")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"height":"24px","src":require("@/assets/images/ic_delete@2x.png"),"alt":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Xóa")])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }