


















































































import Vue from 'vue';
import { ref, defineComponent, reactive, watch, toRefs } from '@vue/composition-api';
import { TableFiltersInput, TableFiltersSelect, TableFiltersDateRange } from '@/components/Shared/index';
import { NormalFilterObject } from '@/InterfaceModel/Filter';
import { returnFilterObject } from '@/utils/TableFilters';
import api from '@/services';
import route from '@/router/index';
export default defineComponent({
	props: {
		listCategories: {
			typeof: Array,
			default: [{ avatar: '', name: '', status: '', actions: '' }],
		},
		tableLoading: {
			type: Boolean,
		},
		headers: {
			type: Array,
			default: [],
		},
		handleFilterChange: {
			type: Function,
		},
		currentBindingUrl: {
			type: Object,
		},
		handleSelectedItem: {
			type: Function,
		},
		handleRemoveItem: {
			type: Function,
		},
		handleSelectedItemDetail: {
			type: Function,
		},
	},
	components: { TableFiltersInput, TableFiltersSelect, TableFiltersDateRange },
	setup: (props, ctx) => {
		const endedThead = ref<number>(40);
		const tableHeight = ref<number>(600);
		let filtersTable = ref<Record<string, unknown>>({});
		let selectedData = ref<Record<string, unknown>>({});
		let selectedDataDetail = ref<Record<string, unknown>>({});

		const setEndedThead = (payload: number) => {
			endedThead.value = payload;
		};
		const setSelectedData = (payload: Record<string, unknown>) => {
			selectedData.value = payload;
		};
		const editItem = (item: any) => {
			route.push(`/danh-muc/chinh-sua/${item.id}`);
		};
		const deleteItem = (item: Record<string, string>) => {
			setSelectedData(item);
			ctx.emit('handleRemoveItem', selectedData.value);
		};
		const setTableHeight = (payload: number) => {
			tableHeight.value = payload;
		};
		const setFiltersTable = (payload: Record<string, unknown>) => {
			filtersTable.value = payload;
		};
		watch(filtersTable, currentValue => {
			// reactive when filter change here
			ctx.emit('handleFilterChange', currentValue);
		});
		//  watch(selectedData, currentValue => {
		//   // reactive when filter change here
		//  });
		//  watch(selectedDataDetail, currentValue => {
		//  });
		return {
			filtersTable,
			tableHeight,
			endedThead,
			selectedData,
			selectedDataDetail,
			setEndedThead,
			setTableHeight,
			setFiltersTable,
			setSelectedData,
			editItem,
			deleteItem,
		};
	},
	data() {
		return {
			arraySort: [],
		};
	},
	mounted() {
		try {
			if (this.$refs.tableMod) {
				const myTable = (this.$refs.tableMod as Vue).$el as HTMLElement;
				const myThead = myTable.querySelector('thead');
				//  this.endedThead= myThead?.clientHeight
				if (myThead?.clientHeight) {
					this.setEndedThead(myThead.clientHeight);
				}
				this.setTableHeight(window.innerHeight - myTable.offsetTop - 80);
			}
		} catch (error) {
			console.log(error);
		}
	},

	methods: {
		listenChange(value: NormalFilterObject) {
			const valObject = returnFilterObject(value);
			const body = {
				...this.filtersTable,
				...valObject,
			};
			this.setFiltersTable(body);
		},
		listenDateChange(value: NormalFilterObject) {
			const valObject = { ...value };
			const body = {
				...this.filtersTable,
				...valObject,
			};
			this.setFiltersTable(body);
		},
		async onChangeActive(data: any) {
			const res = await api.categories.toggleActive(data.id);
			if (!res) {
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 400) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Đổi trạng thái danh mục thành công',
					});
					this.$store.dispatch('categories/getListCategories');
				} else {
					data.is_active = !data.is_active;
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
	},
});
