







import { defineComponent } from '@vue/composition-api';
import DefaultLayout from '@/layouts/default.vue';
import ContainerCategory from '@/components/containers/ContainerCategory.vue';
export default defineComponent({
	components: {
		DefaultLayout,
		ContainerCategory,
	},

	mounted() {
		this.$store.commit('SET_ADDLIB', true);
		this.$store.commit('SET_BREADCRUMB', {
			viewTxt: 'Danh mục',
			ableToBack: false,
			rootRouter: '/',
			hasStatus: false,
			statusTxt: '',
		});
	},
});
